<template>
    <div>
        
        <main-navigation></main-navigation>
        <base-card>

        <!-- secondary {{ secondaryStudents }} -->
    

<div class="row mx-md-n5">
    
  <div class="col px-md-2"><div class="p-3 border bg-light"><strong>{{ secondaryStudents.length }} </strong>  Secondary school students <br> <router-link to="/secondary/students"> Click to view list </router-link> </div></div>
  <div class="col px-md-2"><div class="p-3 border bg-light"> <strong>{{ collegeStudents.length }} </strong> Universities and college students <br> <router-link to="/college/students"> Click to view list </router-link></div></div>
  <div class="col px-md-2"><div class="p-3 border bg-light"> <strong>{{ total_applicants }} </strong> Bursary applicants</div></div>

  
</div>

<!-- <router-link to="/secondary/students">Back to home page </router-link> -->

</base-card>
<base-card>
NEEDY CASES
<div class="row mx-md-n5">
    
    <div class="col px-md-2"><div class="p-3 border bg-light">  <strong> Total Orphans</strong> <br> <small>{{ collegeOrphan.length }} College/Uni  <br> {{ secondaryOrphan.length }} Secondary school  <br> {{ secondaryOrphan.length + collegeOrphan.length  }} Total applicants </small>  </div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light">  <strong> One parent</strong> <br> <small>{{ collegeOneparent.length }} College/Uni  <br> {{ SecondaryOneparent.length }} Secondary school  <br> {{ SecondaryOneparent.length + collegeOneparent.length  }} Total applicants </small>  </div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light">  <strong> Both parents alive</strong> <br> <small>{{ collegeBothparents.length }} College/Uni  <br> {{ SecondaryBothparent.length }} Secondary school  <br> {{ SecondaryBothparent.length + collegeBothparents.length  }} Total applicants </small>  </div></div>


   
  </div>
  
</base-card>

<base-card>
APPLICANTS PER WARD
<div class="row mx-md-n5">
    
    <div class="col px-md-2"><div class="p-3 border bg-light">  <strong> ITIBO WARD</strong> <br> <small>{{ secondaryItibostudent.length }} Sec schools <br> {{ collegeItibostudent.length }} College/university  <br> {{ Itibo_student }} Total applicants </small>  </div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light">  <strong> EKERENYO WARD</strong> <br> <small> {{ secondaryEkerenyostudent.length }} Sec schools <br> {{ collegeEkerenyostudent.length }} College/university  <br> {{ Ekerenyo_student }} Total applicants </small>  </div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light">  <strong> MAGWAGWA WARD</strong> <br> <small> {{ secondaryMagwagwastudent.length }} Sec schools <br> {{ collegeMagwagwastudent.length }} College/university  <br> {{ Magwagwa_student }} Total applicants </small> </div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light">  <strong> BOKEIRA WARD</strong> <br>  <small>{{ secondaryBokeirastudent.length }} Sec schools <br> {{ collegeBokeirastudent.length }} College/university  <br> {{ Bokeira_student }} Total applicants </small>  </div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light">  <strong> BOMWAGAMO </strong> <br> <small> {{ secondaryBomwagamostudent.length }} Sec schools <br> {{ collegeBomwagamostudent.length }} College/university  <br> {{ Bomwagamo_student }} Total applicants </small>  </div></div>

   
  </div>
  
</base-card>


 
   </div>
</template>
<script>
export default {

    computed: {
        total_applicants() {
            return this.secondaryStudents.length + this.collegeStudents.length 

        },
        Itibo_student() {
            return this.collegeItibostudent.length + this.secondaryItibostudent.length 

        },
        Ekerenyo_student() {
            return this.collegeEkerenyostudent.length + this.secondaryEkerenyostudent.length 

        },
        Magwagwa_student() {
            return this.collegeMagwagwastudent.length + this.secondaryMagwagwastudent.length 

        },
        Bokeira_student() {
            return this.collegeBokeirastudent.length + this.secondaryBokeirastudent.length 

        },
        Bomwagamo_student() {
            return this.collegeBomwagamostudent.length + this.secondaryBomwagamostudent.length 

        },
        


        secondaryStudents() {
            return  this.$store.getters.secondaryStudents 

            
        },
        collegeStudents() {
            return  this.$store.getters.collegeStudents

            
        },
        collegeItibostudent() {
            return  this.$store.getters.collegeItibostudent

            
        },
        secondaryItibostudent() {
            return  this.$store.getters.secondaryItibostudent

            
        },
        collegeEkerenyostudent() {
            return  this.$store.getters.collegeEkerenyostudent

            
        },
        secondaryEkerenyostudent() {
            return  this.$store.getters.secondaryEkerenyostudent

            
        },
        collegeMagwagwastudent() {
            return  this.$store.getters.collegeMagwagwastudent

            
        },
        secondaryMagwagwastudent() {
            return  this.$store.getters.secondaryMagwagwastudent

            
        },
        collegeBokeirastudent() {
            return  this.$store.getters.collegeBokeirastudent

            
        },
        secondaryBokeirastudent() {
            return  this.$store.getters.secondaryBokeirastudent

            
        },
        collegeBomwagamostudent() {
            return  this.$store.getters.collegeBomwagamostudent

            
        },
        secondaryBomwagamostudent() {
            return  this.$store.getters.secondaryBomwagamostudent

            
        },

        collegeOrphan() {
            return  this.$store.getters.collegeOrphan

            
        },
        secondaryOrphan() {
            return  this.$store.getters.secondaryOrphan

            
        },
        SecondaryOneparent() {
            return  this.$store.getters.SecondaryOneparent

            
        },
        collegeOneparent() {
            return  this.$store.getters.collegeOneparent

            
        },
        collegeBothparents() {
            return  this.$store.getters.collegeBothparents

            
        },
        SecondaryBothparent() {
            return  this.$store.getters.SecondaryBothparent

            
        },
        
       

    },
     created() {
        this.$store.dispatch('LoadCollegestudents');  
        this.$store.dispatch('LoadSecondarystudents');  
    
    
    
    
  },

}
</script>

<style scoped>

.dashboard {
    background-color:azure;
    box-sizing: border-box;
    padding: 3px;
    display: grid;
    grid-template-rows: 1fr 4fr;
    grid-template-columns: 1fr 2fr 4fr;
    grid-gap: 0ch;
    height: 100vh;
    
    grid-template-areas: 
    /* "theader theader theader" */
    "sidebar sheader sheader"
    "sidebar smain smain"

    ;
    


}
.sidebar {
    grid-area: sidebar;
    background-color:rgb(113, 180, 47);

}
.sheader {
    grid-area: sheader;
    background-color: rgb(221, 216, 209);
}
.smain {
    grid-area: main;
    background-color:beige;
}
.theader {
    grid-area: theader;
    grid-area: theader;
}

</style>