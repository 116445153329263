<template>
    <div>
        <main-navigation></main-navigation>
  <base-card>
    <!-- <input type="text" v-model="search" placeholder="Search students" /> -->
    <div id="element-to-convert">
    <!-- <img width="100" height="50" src="../../assets/NMC.png" /> -->
  
   <small> <table class="table table-sm">
      <thead>
        <tr>
          <th>Institution Name</th>
          <th>Number of students</th>
          <!-- <th><h3>Parent name</h3></th>
          <th><h3>phone number</h3></th> -->
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="college in colleges" :key="college.id">
          <td> <router-link
            :to="`/college/name/${college.the_code}`"
          >
            {{ college.school_name }}
          </router-link> </td> 
          <td>{{ college.numberofstudents }}</td>
          <!-- <td>{{ student.parent_name }}</td>
          <td>{{ student.phone_number }}</td> -->
          
          <!-- <td><router-link :to="'/student/' + student.student_id"> Show details</router-link></td> -->
          <!-- <td> <router-link :to="`/student/:{student.id_number}`" class="btn btnn-primary">Details</router-link> </td> -->
        </tr>
      </tbody>
    </table>
</small>
    </div>
    <button @click="exportToPDF">Export to PDF</button>
    <br>
    <router-link to="/">Go back</router-link>
</base-card>
  </div>

  </template>
  <script>
  import html2pdf from 'html2pdf.js'
  
  
  
  export default {
    methods: {
      exportToPDF() {
              html2pdf(document.getElementById('element-to-convert'));
          }
  
  
    },
    data() {
      return {
        search: "",
      };
    },
    computed: {
      colleges() {
        return this.$store.getters.colleges;
      },
      
    },
    created() {
      this.$store.dispatch("LoadColleges");
      console.log(this.colleges)
    },
  };
  </script>
  <style scoped>
  input {
    /* display: block; */
    width: 350px;
    margin: 20px auto;
    padding: 10px 45px;
    background: white no-repeat 15px center;
    background-size: 15px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  </style>
  