<template>
    <div>
      
        <base-navigation></base-navigation>
        <!-- Background image -->
        <div
          id="carouselExampleSlidesOnly"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="../../assets/nyamoko1.jpg"
                alt="First slide"
              />
              <div class="carousel-caption d-none d-md-block text-black">
                <h2 class="">Online applications</h2>
                <p class="">
                   Thanks to all who applied. We are processing the applications to award bursaries as soon as possible.  
                </p>
               
                <a class="base-button" href="#">Application closed</a>
  
              </div>
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../../assets/nyamoko4.jpg"
                alt="Second slide"
              />
              <div class="carousel-caption d-none d-md-block text-black">
                <h3 class="text">Faster processing</h3>
                <p class="text">
                   Thanks to all who applied. We are processing the applications to award bursaries as soon as possible.  
                </p>
                <a class="base-button" href="#">Application closed</a>
  
              </div>
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../../assets/nyamoko4.jpg"
                alt="Third slide"
              />
              <div class="carousel-caption d-none d-md-block text-black">
                <h3 class="">Fair allocations</h3>
                <p class="text">
                   Thanks to all who applied. We are processing the applications to award bursaries as soon as possible.  
                </p>
                <a class="base-button" href="#">Application closed</a>
  
              </div>
            </div>
          </div>
        </div>
     <br>
     
  
      <!-- features section -->
      
      <br>
      <br>
      <!-- end of features section -->
  
      <!-- end of testimonial section -->
      <section class="container" id="contact">
  
        <div id="contact" class="contact-area section-padding">
      <div class="container">										
          <div class="section-title text-center">
              <h3>Get in Touch</h3>
              <p>Talk to us, visit us and get all your consituency needs addressed.</p>
          </div>					
          <div class="row">
              <div class="col-lg-7">	
                  <div class="contact">
                      <form class="form" name="enq" method="post" action="#" onsubmit="return validation();">
                          <div class="row">
                              <div class="form-group col-md-6">
                                  <input type="text" name="name" class="form-control" placeholder="Name" required="required">
                              </div>
                              <div class="form-group col-md-6">
                                  <input type="email" name="email" class="form-control" placeholder="Email" required="required">
                              </div>
                              <div class="form-group col-md-12">
                                  <input type="text" name="subject" class="form-control" placeholder="Subject" required="required">
                              </div>
                              <div class="form-group col-md-12">
                                  <textarea rows="6" name="message" class="form-control" placeholder="Your Message" required="required"></textarea>
                              </div>
                              <div class="col-md-12 text-center">
                                  <button type="submit" value="Send message" name="submit" id="submitButton" class="btn btn-contact-bg" title="Submit Your Message!">Send Message</button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div><!--- END COL --> 
              <div class="col-lg-5">
                  <div class="single_address">
                      <i class="fa fa-map-marker"></i>
                      <h4>Our Address</h4>
                      <p>DCC Building, Ground floor, Ekerenyo</p>
                  </div>
                  <div class="single_address">
                      <i class="fa fa-envelope"></i>
                      <h4>Send your message</h4>
                      <p>info@northmugirango.org</p>
                  </div>
                  <div class="single_address">
                      <i class="fa fa-phone"></i>
                      <h4>Call us on</h4>
                      <p>0725029795</p>
                  </div>
                  <div class="single_address">
                      <i class="fa fa-clock-o"></i>
                      <h4>Work Time</h4>
                      <p>Mon - Fri: 08.00 am - 07.00pm. <br></p>
                  </div>					
              </div><!--- END COL --> 
          </div><!--- END ROW -->
      </div><!--- END CONTAINER -->	
  </div>
        <!-- <section class="mb-4" > -->
  
        <!--Section heading-->
        
          <!--Grid column-->
       
      </section>
      <!--Section: Contact v.2-->
  
      <!-- contact section -->
    </div>
  </template>
  <script>
  export default {
    computed: {
      isLoggedIn() {
        return this.$store.getters.isLoggedIn;
      },
    },
  
    methods: {
      handleLogout() {
        this.$store.dispatch("clearToken");
        localStorage.removeItem("token"); // clear your user's token from localstorage
        localStorage.removeItem("user"); // clear your user from localstorage
  
        this.$router.push("/login");
      },
    },
    data() {
      return {
        user: "",
      };
    },
    mounted() {
      let user = localStorage.getItem("user");
      this.user = JSON.parse(user);
    },
  };
  </script>
  
  <style scoped>
  body{margin-top:20px;
  background:#eee;
  }
  .contact-area {
      background-attachment: fixed;
      background-size: cover;
      background-position: center;
  }
  
  @media only screen and (max-width:768px) {
      .contact {
          margin-bottom: 60px;
      }
  }
  
  .contact input {
      background: #fff;
      border: 1px solid #fff;
      border-radius: 3px;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #232434;
      font-size: 16px;
      height: 60px;
      padding: 10px;
      width: 100%;
      font-family: 'poppins', sans-serif;
      padding-left: 30px;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
  }
  
  .contact textarea {
      background: #fff;
      border: 1px solid #fff;
      border-radius: 3px;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #232434;
      font-size: 16px;
      padding: 10px;
      width: 100%;
      font-family: 'poppins', sans-serif;
      padding-left: 30px;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
  }
  
  .contact input:focus {
      background: #fff;
      border: 1px solid #fff;
      color: #232434;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 none;
  }
  
  .contact textarea:focus {
      background: #fff;
      border: 1px solid #fff;
      color: #232434;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 none;
  }
  
  .form-control::placeholder {
      color: #232434;
      opacity: 1;
  }
  
  .btn-contact-bg {
      border-radius: 30px;
      color: #fff;
      outline: medium none !important;
      padding: 15px 27px;
      text-transform: capitalize;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      background: #7564e5;
      font-family: 'poppins', sans-serif;
      cursor: pointer;
      width: 100%;
  }
  
  .btn-contact-bg:hover,
  .btn-contact-bg:focus {
      background: #232434;
      color: #fff;
  }
  
  /*START ADDRESS*/
  
  .single_address {
      overflow: hidden;
      margin-bottom: 10px;
      padding-left: 40px;
  }
  
  @media only screen and (max-width:768px) {
      .single_address {
          padding-left: 0px;
      }
  }
  
  .single_address i {
      background: #f6f6f6;
      color: #7564e5;
      border-radius: 30px;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      float: left;
      margin-right: 14px;
      font-size: 22px;
      -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
  }
  
  .single_address:hover i {
      background: #7564e5;
      color: #fff;
  }
  
  .single_address h4 {
      font-size: 18px;
      margin-bottom: 0px;
      overflow: hidden;
      font-weight: 600;
  }
  
  .single_address p {
      overflow: hidden;
      margin-top: 5px;
  }
  
  .section-title h1 {
      font-size: 44px;
      font-weight: 500;
      margin-top: 0;
      position: relative;
      text-transform: capitalize;
      margin-bottom: 15px;
  }
  .section-title p {
      padding: 0 10px;
      width: 70%;
      margin: auto;
      letter-spacing: 1px;
  }
  .section-title {
      margin-bottom: 60px;
  }
  .text-center {
      text-align: center!important;
  }
  .carousel-item img {
    max-height: 90vh;
    min-width: auto;
  }
  .carousel-caption {
    background-color:rgba(250, 244, 244, 0.582);
    border-radius: 55px 55px 55px 55px;
    overflow: hidden;
  
    
  }
  button,
  a {
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    font: inherit;
    background-color: #3a0061;
    border: 1px solid #3a0061;
    color: white;
    cursor: pointer;
    border-radius: 30px;
    margin-right: 0.5rem;
    display: inline-block;
  }
  a:hover,
  a:active,
  button:hover,
  button:active {
    background-color: #270041;
    border-color: #270041;
  }
  .flat {
    background-color: transparent;
    color: #3a0061;
    border: none;
  }
  .outline {
    background-color: transparent;
    border-color: #270041;
    color: #270041;
  }
  .flat:hover,
  .flat:active,
  .outline:hover,
  .outline:active {
    background-color: #edd2ff;
  }
  </style>
  
