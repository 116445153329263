<template>
    <div>
     <main-navigation></main-navigation>

    
    <base-card>
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <!-- <th><h3>Client Id</h3></th> -->
                <th><small>Name</small></th>
                <th><small> Institution Name</small></th>
                <th><small>Admission Number</small></th>
                <th><small>Id Number</small></th>
                <th><small>level of study</small></th>
                <th><small>Ward</small></th>
                <th><small>Needy status</small></th>
                <th><small>Amount awarded</small></th>
                <!-- <th><h6>Application date</h6></th> -->
            </tr>
            </thead> 
            <tbody>
                <tr v-for="student in students.slice().reverse()" :key="student.id">
                    
                    <!-- <td>{{ payment.client_id}}</td> -->
                    <td> <small> {{ student.studentName }}</small></td>
                    <td><small>{{ student.schoolName}}</small></td>

                    <td><small>{{ student.student_admission_number}}</small></td>
                    <td><small>{{ student.idNumber}}</small></td>
                    <td><small>{{ student.student_form}}</small></td>
                    <td><small>{{ student.ward}}</small></td>
                    
                    <td><small>{{ student.needy_status}}</small></td>
                    <td><small>{{ student.amount_awarded}}</small></td>


                    <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
                    
                </tr>
            </tbody>
    </table>
    
</base-card>

<!-- <router-link to="/receive/payment">Receive payment</router-link> -->
</div>
</template>
<script>



export default {
    computed: {
        students() {
            return  this.$store.getters.collegeStudents

            
        }

    },
     created() {
    this.$store.dispatch('LoadCollegestudents');  
    
    
  },

}

</script>
