<template>
    <div>
     <main-navigation></main-navigation>
    <base-card>
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <!-- <th><h3>Client Id</h3></th> -->
                <th><h6>Full_Student Names</h6></th>
                <th><h6>Institution Name</h6></th>
                <th><h6>Reg Number</h6></th>
                <th><h6>Birth certificate</h6></th>
                <th><h6>Class</h6></th>
                <th><h6>Ward</h6></th>
                <th><h6>Needystatuscase</h6></th>
                <th><h6>Amount awarded</h6></th>
                <!-- <th><h6>Application date</h6></th> -->
            </tr>
            </thead> 
            <tbody>
                <tr v-for="student in students.slice().reverse()" :key="student.id">
                    
                  <!-- <td>{{ payment.client_id}}</td> -->
                    <td> <small> {{ student.studentName }}</small></td>
                    <td><small>{{ student.schoolName}}</small></td>

                    <td><small>{{ student.adminNumber}}</small></td>
                    <td><small>{{ student.idNumber}}</small></td>
                    <td><small>{{ student.student_form}}</small></td>
                    <td><small>{{ student.ward}}</small></td>
                    
                    <td><small>{{ student.needy_status}}</small></td>
                    <td><small>{{ student.amount_awarded}}</small></td>
                    <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
              
                    
                </tr>
            </tbody>
    </table>
    
</base-card>

<!-- <router-link to="/receive/payment">Receive payment</router-link> -->
</div>
</template>
<script>



export default {
    computed: {
        students() {
            return  this.$store.getters.secondaryStudents

            
        }

    },
     created() {
    this.$store.dispatch('LoadSecondarystudents');  
    
    
  },

}

</script>
